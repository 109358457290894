/* body {
  overflow-y:hidden;
} */

#root {
  margin: auto;
}

a{
  text-decoration: none !important;
}

.headerBar{
  background-color: #333;
  border-bottom: 0.5px solid #DDD; 
  color: #FFF;
  width: 100%;
  position: absolute;
  top: 0px; 
  left: 0px;
  margin: 0px;
  min-height: 60px;
}

.usePointer{
  cursor: pointer;
}

.spacer{
  clear:both;
}

.hidden{
  display:none;
}

.header{
  font-size: 24pt;
}

.bold{
  font-weight: bold;
}

.main-header {
  /* min-height: 60px; */
}

.MuiTableCell-head{
  -webkit-user-select: auto!important;
  -moz-user-select: auto!important;
  -ms-user-select: auto!important;
  user-select: auto!important;
}

.compensationHeader{
  display: inline-block;
  min-height: 58px;
  width: 100%;
}

.sectionTableHeader{
  font-size: 18pt;
  font-weight: bold;
}

.quickLayoutContainer{
  float: right; 
  height: 40px; 
  width:  40px; 
  padding: 0px 0px 0px 8px;
}

.resetLayoutContainer{
  float: right; 
  height: 40px; 
  width:  40px; 
  padding: 0px 0px 0px 8px;
}

.editCommentBlock{
  float:right;
}

.editCommentLink{
  color:#00C;
  cursor: pointer;
}

.editNoteBlock{
  float:right;
}

.editNoteLink{
  color:#00C;
  cursor: pointer;
}

.saveLayoutContainer{
  float: right; 
  height: 40px; 
  width:  40px; 
  padding: 0px 0px 0px 8px;
}

.iconContainer {
  border: 1px solid #999;
  display: inline-block;
  padding: 0px 10px 10px 10px;
  margin: 0px 10px 0px 10px;
  text-align: center;
}

.iconContainer:hover {
  border: 1px solid #DDD;
  background-color: #DDD;
  cursor: pointer;
}

.emailContainer {
  display: inline-block;
  padding: 0px 10px 10px 10px;
}

.previewEmailBlock{
  border: 1px solid #DDD;
}

#geo-logo {
  font: "Helvetica Neue,Helvetica,Arial,sans-serif";
  font-size: 14pt;
  margin:15px 0px 10px 0px;
  /* font-weight:bold; */
  text-align:left;
  /* padding: 10px 0px 0px 20px; */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.startDate input{
  width: 250px;
  height: 33px;
}

.endDate input{
  width: 250px;
  height: 33px;
}

.logDatetime input{
  margin-left: 15px;
  width: 183px;
  height: 33px;
}

.jsgrid-cell.tnkGridCell {
  overflow:hidden;
}

.grid-default-dimensions {
  width: 90%;
  margin: 20px 5%;
}

.hidden {
  display:none;
}

.displayed, .displayedBlock {
  display:block;
}

.displayedInline {
  display:inline;
}

.gridLink{
  color:#00F;
}

#detailsModalContainer .modal-dialog {
  max-width: 800px;
}

.orgHeiarchy{}

.orgHeiarchy > div {
  display: inline-block;
  vertical-align: middle;
}

.orgHeiarchy .managersNode div {
  display: inline-block;
  padding: 10px;
}

.orgHeiarchy .managerDetails {
  border: 1px solid #CCC;
}

.orgHeiarchy .managerDetails.bold{
  font-weight: bold;
}

.orgHeiarchy .directReportsNode .directReportUser{
  border: 1px solid #CCC;
}

.orgHeiarchy .directReportsNode div{
  padding: 10px;
}

.hrUserInputLabel {
  display:inline-block;
  width: 100px;
}

.salaryGroupLabel {
  display:inline-block;
  width: 200px;
  margin-right:5px;
}

.rangeGroupLabel {
  display:inline-block;
  width: 200px;
  margin-right:5px;
}

.salaryHistoryItem{
  display: inline-block;
  width: 300px;
  border: 1px solid #DDD;
}

.hrAdjustmentRow {
  border: 1px solid #CCC;
  max-width: 1200px;
  border-radius: 6px;
  background-color: #EEF;
}

.hrAdjustmentRow.wide {
  border: 1px solid #CCC;
  max-width: 1400px;
  border-radius: 6px;
  background-color: #EEF;
}

.hrViewPendingRequests{
  border-left: 1px solid #DDD;
  display: inline-block;
  width: 100px;
  height: 80px;
  vertical-align: top;
}

.hrViewPendingRequests.wide{
  display: inline-block;
  width: 200px;
  border-left: 1px solid #DDD;
  height: 80px;
  vertical-align: top;
}

.hrLabelPendingRequests{
  display: inline-block;
  width: 100px;
  border-left: 1px solid #DDD;
  height: 80px;
  vertical-align: top;
}

.hrLabelPendingRequests.wide{
  width: 200px;
}

.hrLabelEmployeesRequests{
  display: inline-block;
  width: 170px;
  border-left: 1px solid #DDD;
  height: 80px;
  vertical-align: top;
}

.hrDownloadChanges{
  display: inline-block;
  width: 300px;
  border: 1px solid #DDD;
  height: 80px;
  vertical-align: top;
}

.hrLabelDateRequests{
  display: inline-block;
  width: 230px;
  border-left: 1px solid #DDD;
  height: 80px;
  vertical-align: top;
}

.hrLabelUuidRequests{
  display: inline-block;
  width: 100px;
  border-left: 1px solid #DDD;
  height: 80px;
  vertical-align: top;
}

.modalRequestResultBody{
  height: 300px;
  overflow: scroll;
}

.delegateLabel {
  display: inline-block;
  width: 100px;
  border: 1px solid #DDD;
}

.reviewerLabel {
  display: inline-block;
  width: 100px;
  border: 1px solid #DDD;
}

.jobTitleLabel {
  display:inline-block;
  width: 150px;
  margin-right:5px;
}

.budgetExceeded{
  color: #F00;
}

.hr-editor-wrapper{}

.hr-editor-input{
  width: 1150px;
  height: 250px!important;
  border: 1px solid #DDD;
}

.adjustmentModal{
  overflow-y: scroll;
  max-height: 300px;
}

.adjustmentModal .logUser{
  display:inline-block;
  width: 300px;
}
.adjustmentModal .logDate{
  display:inline-block;
  width: 150px;
}
.adjustmentModal .logAction{
  display:inline-block;
  width: 200px;
}
.adjustmentModal .logDetails{
  display:inline-block;
  width: 100px;  
}

.reportCell{
  display: inline-block;
  width: 200px;
  border: 1px solid #DDD;
}

.reportCell.wide{
  width: 400px;
}

.add_reviewer_title{
  display:inline-block;
  width: 150px;
}

.employeeListRevieweesModalBody{
  max-height: 300px;
  overflow-y: scroll;
}

.compensationCommentLog{
  max-height:150px;
  overflow-y:scroll;
}

.compensationCommentEntry{
  max-height:300px;
}

.adjustmentComment {
  border: 1px solid #AAA;
  background-color: #EEE;
  border-radius: 6px;
  padding: 5px;
}

.adjustmentCommentBlock{
  display: flex;
  border: 1px solid #BBB;
  background-color: #DDD;
  padding: 2px;
}

.adjustmentCommentItem{
  display:inline-block;
  width: 147px;  
  align-self: "stretch";
}

.adjustmentCommentItem.wide{
  border: 1px solid #DDD;
  display:inline-block;
  width: 300px;  
}

.adjustmentCommentItem.edit{
  border: 1px solid #DDD;
  display:inline-block;
  width: 80px;  
}

.adjustmentCommentItem.editing{
  background-color: #DDF;  
}

.adjustmentNote {
  border: 1px solid #AAA;
  background-color: #EEE;
  border-radius: 6px;
  padding: 5px;
}

.adjustmentNoteBlock {
  display: flex;
  border: 1px solid #BBB;
  background-color: #DDD;
  padding: 2px;
}

.adjustmentNoteItem{
  display:inline-block;
  width: 147px;
  align-self:flex;  
}

.adjustmentNoteItem.wide{
  border: 1px solid #DDD;
  display:inline-block;
  width: 300px;  
}

.adjustmentNoteItem.edit{
  border: 1px solid #DDD;
  display:inline-block;
  width: 80px;  
}

.adjustmentNoteItem.editing{
  background-color: #DDF;  
}

#menu #menu-dropdown {
  position: absolute;
  right: 33px;
}

.compensationTitle {
  line-height: 120% !important;
  display: inline-block;
  vertical-align: top;
}